<template>
  <v-app class="bg" t-data="app-bg" :style="backgroundImage">
    <v-alert-success :show="isSuccess" :close="closeAlert" :message="successMessages"></v-alert-success>
    <v-alert-hospital-list :show="hospitalAlert" :close="closeHospitalAlert" :uid="uid" :orgs="orgs" :bind="bind"></v-alert-hospital-list>
    <v-alert-term-and-condition :show="termAlert" :accept="acceptTermAndCondition" :reject="rejectTermAndCondition"></v-alert-term-and-condition>
    <v-alert-error :show="errorAlert" :close="closeAlert" :msg="errorMsg"></v-alert-error>
    <v-popup-loading :isLoading="isLoading" />
    <v-main>
      <v-card
          class="mx-auto my-12"
          color="white"
          max-width="75%"
          style="padding: 5%"
      >
        <v-row class="justify-center">
          <v-img
            t-data="hospital-logo"
            :src="hospitalLogo"
            alt=""
            width="360"
            max-width="60%"
            max-height="30%">
          </v-img>
        </v-row>
        <v-row align="center">
          <v-checkbox t-data="info-input" v-model="isForeigner" label="Foreigner" @change="resetId"></v-checkbox>
        </v-row>
        <v-form ref="form" lazy-validation>
          <v-row class="test">
            <v-text-field
                t-data="info-input"
                v-if="isForeigner"
                label="Passport Number"
                :rules="passportRules"
                v-model="idCard"
            ></v-text-field>
          </v-row>
          <v-row>
            <v-text-field
                t-data="info-input"
                v-if="!isForeigner"
                label="National ID"
                type="number"
                :rules="idCardRules"
                v-model="idCard"
            ></v-text-field>
          </v-row>
          <v-row>
            <vue-phone-number-input
                t-data="info-input"
                v-model="phone"
                default-country-code="TH"
                @update="update"
            />
          </v-row>
        </v-form>
        <v-row class="justify-center">
          <v-btn t-data="confirm-btn" style="margin-top: 20px;" color="primary" @click="bindPatient" text>Confirm</v-btn>
        </v-row>
      </v-card>
    </v-main>
  </v-app>
</template>

<script>
  import VuePhoneNumberInput from 'vue-phone-number-input'
  import 'vue-phone-number-input/dist/vue-phone-number-input.css';
  import client from '@/apis/http-client'
  import AlertSuccess from '../../components/AlertSuccess'
  import AlertError from '../../components/AlertError'
  import AlertHospitalList from '../../components/AlertHospitalList'
  import AlertTermAndCondition from '../../components/AlertTermAndCondition'
  import PopupLoading from '../../components/PopupLoading'
  import {imageHospitalBg, imageHospitalLogo} from '@/constants/constants'

  export default {
    components: {
      'v-alert-success': AlertSuccess,
      'v-alert-error': AlertError,
      'v-alert-hospital-list': AlertHospitalList,
      'v-alert-term-and-condition': AlertTermAndCondition,
      'v-popup-loading': PopupLoading,
      'vue-phone-number-input': VuePhoneNumberInput,
    },
    data() {
      return {
        phone: '',
        idCard: '',
        uid: '',
        isSuccess: false,
        hospitalAlert: false,
        termAlert: false,
        errorAlert: false,
        isLoading: false,
        successMessages: 'Your binding is successful.',
        isForeigner: false,
        errorMsg: '',
        validatePhoneNumber: false,
        formattedNumber: '',
        link: '',
        orgs: [],
        idCardRules: [v => v.length === 13 || 'National ID (Thai) must be 13 characters'],
        passportRules: [v => !!v || 'Passport number is required']
      }
    },
    computed: {
      backgroundImage() {
        return {
          "background-image": `url(${imageHospitalBg})`
        }
      },
      hospitalLogo() {
        return imageHospitalLogo
      },
    },
    methods: {
      reset: function () {
        this.phone = ''
        this.formattedNumber = ''
        this.validatePhoneNumber = false,
        this.idCard = ''
        this.uid = ''
        this.$refs.form.resetValidation()
      },
      resetId: function () {
        this.idCard = ''
      },
      bindPatient: async function () {
        if (this.$refs.form.validate() && this.validatePhoneNumber) {
          try {
            this.isLoading = true
            const user = await client.secureHttpClient.post('/por/api/user/search/uid', {
              id_card: this.idCard,
              phone_number: this.formattedNumber,
            })
            this.uid = user.data.data.uid
            const orgs = await client.secureHttpClient.post('/por/api/binding/organizations', {
              uid: this.uid,
              personalid: this.idCard,
            })
            if (orgs.data.data.visited.length === 0) {
            this.errorMsg = 'You do not have any branch that can bind.'
              this.isLoading = false
              return this.errorAlert = true
            }
            this.orgs = orgs.data.data.visited
            this.isLoading = false
            this.termAlert = true
          } catch (e) {
            this.isLoading = false
            this.errorMsg = 'This phone number and national id / passport do not match.'
            this.errorAlert = true
            this.reset()
          }
        }
        else {
          this.errorMsg = 'This phone number or national id / passport is not correct.'
          this.errorAlert = true
        }
      },
      closeAlert: function () {
        this.errorAlert = false
        this.isSuccess = false
        this.reset()
      },
      closeHospitalAlert: function () {
        this.hospitalAlert = false
        this.reset()
      },
      acceptTermAndCondition: async function (termAndConId) {
        try {
          await client.secureHttpClient.post('/por/api/term_and_condition/accept', {
            uid: this.uid,
            tnc_id: termAndConId,
            is_accept: true,
          })
          this.termAlert = false
          this.hospitalAlert = true
        }
        catch (e) {
          this.errorMsg = 'Service is unavailable. Please try again later.'
          this.errorAlert = true
          this.termAlert = false
        }
      },
      rejectTermAndCondition: function () {
        this.termAlert = false
        this.reset()
      },
      update: function (data) {
        this.formattedNumber = data.formattedNumber
        this.validatePhoneNumber = data.isValid
      },
      bind: async function (selected) {
        try {
          if (selected.length === 0 || selected.length > 5) {
          this.errorMsg = 'Please select between 1-5 branch.'
            this.errorAlert = true
            return false
          }
          this.hospitalAlert = false
          this.isLoading = true

          await client.secureHttpClient.post('/por/api/binding/create-multi-bind', {
            uid: this.uid,
            personalid: this.idCard,
            phoneno: this.formattedNumber,
            org_codename: selected,
            lang: 'en',
          })
          this.isLoading = false
          this.reset()
          this.isSuccess = true
          return true
        }
        catch (e) {
          this.isLoading = false
          this.errorAlert = true
          this.reset()
          return true
        }
      }
    },
  }
</script>

<style lang="scss" scoped>
.theme--light.v-card {
  background-color: transparent;
}
.bg {
  background-repeat: no-repeat, repeat;
  background-size: cover;
  background-position: right top;
}
</style>

<style>
</style>
