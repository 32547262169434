<template>
    <v-dialog v-model="show" max-width="600">
      <v-card style="padding: 15px">
        <v-card-title t-data="headline">Please select at least one branch</v-card-title>
        <v-flex v-for="item in this.orgs" :key="item.org_codename">
          <v-card>
            <v-row align="center" style="padding-left: 20px;">
              <v-checkbox
                  v-model="selected"
                  :value="item.org_codename"
              ></v-checkbox>
              <v-img max-width="200" :src="item.binding_card_logo_url"></v-img>
            </v-row>
          </v-card>
        </v-flex>
          <v-row justify="end" style="margin-top: 15px">
            <v-btn t-data="action-btn" style="margin-right: 10px" color="primary" @click="submit">Submit</v-btn>
            <v-btn t-data="close-btn" color="black darken-1" text @click="closePopup">Close</v-btn>
          </v-row>
      </v-card>
    </v-dialog>
</template>

<script>

  export default {
    name: "AlertHospitalList",
    components: {},
    props: {
      show: {type: Boolean},
      uid: {type: String},
      close: {type: Function},
      bind: {type: Function},
      orgs: {type: Array},
    },
    methods: {
      closePopup() {
        this.close()
        this.selected = []
      },
      async submit() {
        const isDone = await this.bind(this.selected)
        if (isDone) this.selected = []
      },
    },
    computed:{
      displayText() {
        return this.link
          ? `${window.location}/signup?url=${Buffer.from(this.link).toString('base64')}`
          : ''
      },
    },
    data() {
      return {
        binded_orgs: [],
        selected: [],
      }
    },
  }
</script>


<style scoped>

</style>

