<template>
  <v-dialog v-model="show" max-width="600">
    <v-card style="padding: 20px" v-if="termAndConditionObject.id !== null">
      <v-card-title class="headline justify-center" t-data="headline">Consent for Personal Health Data</v-card-title>
        <iframe style="width:100%" :srcdoc="englishContent" t-data="content"></iframe>
      <v-row justify="center" style="margin-top: 15px">
        <v-btn color="primary" @click="accept(termAndConditionObject.id)" t-data="accept-btn">Accept</v-btn>
      </v-row>
      <v-row justify="center" style="margin-top: 8px">
        <v-btn color="error" text @click="reject" t-data="cancel-btn">Cancel</v-btn>
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
  import {mapActions, mapState} from 'vuex'

  export default {
    name: "AlertGenerateLink",
    components: {},
    props: {
      show: {type: Boolean},
      accept: {type: Function},
      reject: {type: Function},
    },
    beforeRouteLeave(to, from, next) {
      this.resetState()
      next()
    },
    methods: {
      closePopup() {
        this.close()
      },
      ...mapActions('termAndCondition', ['setTypeTermAndConditionObject', 'getLatestVersion', 'resetState'])
    },
    computed:{
      englishContent() {
        const engContents = this.termAndConditionObject.contents.filter(c => c.code === 'en')
        if (engContents.length > 0) {
          return engContents[0].description
        } else {
          return ''
        }
      },
      ...mapState('termAndCondition', ['termAndConditionObject'])
    },
    async created() {
      this.setTypeTermAndConditionObject('binding')
      await this.getLatestVersion()
    },
  }
</script>


<style scoped>

</style>

